<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="10" sm="12">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="organization"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                       <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="service_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('service_name.service_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.service_id"
                            :options="serviceList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Notice (En)" vid="notice" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="notice"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.notice')}} {{$t('dam-config.en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="notice"
                              v-model="formData.notice"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Notice (Bn)" vid="notice_bn">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="notice_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.notice')}} {{$t('dam-config.bn')}}
                          </template>
                          <b-form-input
                              id="notice_bn"
                              v-model="formData.notice_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        </b-form-group>
                      </ValidationProvider>
                       <ValidationProvider name="fiscal year id" vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="formData.fiscal_year_id"
                              :options="fiscalList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Meeting Date" vid="meeting_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="meeting_date"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.meeting_date')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="meeting_date"
                              v-model="formData.meeting_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Start Date" vid="start_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="start_date"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.start_date')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="start_date"
                              v-model="formData.start_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="End Date" vid="end_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="end_date"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.end_date')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="end_date"
                              v-model="formData.end_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Description (En)" vid="description_en">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="description"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('globalTrans.description')}}
                        </template>
                          <b-form-textarea
                            id="address_en"
                            v-model="formData.description"
                            rows="1"
                            max-rows="2"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Description (Bn)" vid="description_bn">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="description_bn"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('globalTrans.description')}}  {{$t('dam-config.bn')}}
                        </template>
                          <b-form-textarea
                            id="address_en"
                            v-model="formData.description_bn"
                            rows="1"
                            max-rows="2"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { noticeStore, noticeUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  mixins: [commonAuth],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        step_name: '',
        step_name_bn: '',
        common_grid: [],
        service_id: 0,
        org_id: 0,
        description: 0,
        description_bn: 0,
        fiscal_year_id: 0,
        meeting_date: ''
      },
      loading: false,
      serviceList: []
    }
  },
  created () {
    this.formData.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.getformDataData()
      this.formData = Object.assign(tmp)
    }
  },
  mounted () {
    core.index()
    flatpickr('#meeting_date', {})
    flatpickr('#start_date', {})
    flatpickr('#end_date', {})
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    }
  },
  watch: {
    'formData.org_id': function (newVal) {
      this.serviceList = this.getServiceList(newVal)
    }
  },
  methods: {
     getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    getformDataData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${noticeUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, noticeStore, this.formData)
      }
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
